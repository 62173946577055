<template>
  <div class="mb-8">
    <div class="mb-8 flex justify-between items-center">
      <div class="text-lg font-bold">Region</div>
      <button class="btn btn-blue btn-sm" @click="$refs.regionModal.open()">
        + Add New Region
      </button>
    </div>

    <template v-if="getFormError(regionResource)">
      <div class="alert alert-red-soft mb-10">
        <span class="alert-icon">!</span>
        <span>{{ getFormError(regionResource) }}</span>
      </div>
    </template>

    <datatable
      :data="regions"
      :columns="regionResource.columns"
      :actions="regionResource.actions"
      :fillable="true"
      table="ref"
    />

    <modal className="w-11/12 sm:w-443px" ref="regionModal">
      <h1 class="text-2xl text-left font-bold mb-8">
        Add Region
      </h1>
      <form @submit.prevent="addRegion">
        <template v-if="getFormError(regionForm)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon">!</span>
            <span>{{ getFormError(regionForm) }}</span>
          </div>
        </template>

        <form-group
          name="name"
          :form="regionForm"
          v-model="regionForm.data.name.value"
        >
          Name
        </form-group>

        <div class="mt-3">
          <form-group
            type="textarea"
            name="description"
            :form="regionForm"
            v-model="regionForm.data.description.value"
          >
            Description
          </form-group>
        </div>

        <div class="flex justify-center">
          <button
            class="btn btn-blue w-full font-normal"
            type="submit"
            :disabled="regionForm.loading"
          >
            Submit
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>
<script>
export default {
  props: {
    regions: {
      type: Array,
      default: () => []
    },
    profileId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      regionForm: this.$options.basicForm([
        { name: 'merchant_id', value: this.profileId },
        { name: 'name', rules: 'required|aplpha' },
        { name: 'description', rules: 'nullable' }
      ]),
      regionResource: this.$options.resource([], {
        columns: [
          {
            name: 'name',
            th: 'Name'
          },
          {
            name: 'description',
            th: 'Description'
          },
          {
            name: 'created_at',
            th: 'Created At',
            render: (loan, created_at) =>
              this.$options.filters.dateFormat(created_at, 'D, dd M Y')
          }
        ],
        actions: [
          {
            text: 'delete',
            class: 'border-red-500 text-red-500 rounded-sm px-4 py-2',
            action: this.deleteRegion
          }
        ]
      })
    };
  },
  methods: {
    async addRegion() {
      if (!this.validateForm(this.regionForm)) return false;

      this.regionForm.loading = true;
      this.regionForm.error = false;

      await this.sendRequest('admin.merchants.region.add', {
        data: this.getFormData(this.regionForm),
        success: () => {
          this.resetForm(this.regionForm);
          this.$refs.regionModal.close();
          this.$emit('success');
        },
        error: error => (this.regionForm.error = error)
      });
      this.regionForm.loading = false;
    },
    async deleteRegion({ id }) {
      await this.sendRequest('admin.merchants.region.destroy', id, {
        success: () => this.$emit('success'),
        error: error => (this.regionResource.error = error)
      });
    }
  }
};
</script>
